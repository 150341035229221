import React from "react";
import {Fade} from "react-reveal";
import styled from "styled-components";
import theme from "../../styles/theme";
import {Col, Row} from "styled-bootstrap-grid";

const ClientsList = styled.div`
  width: 100%;
`;

const RowStyled = styled(Row)`
  display: ${props => props.type === 'dark' && 'none'};
  height: 100%;
`;

const ColStyled = styled(Col)`
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.start ? 'flex-start' : 'center'};
  box-sizing: border-box;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: ${props => props.logos && '215px'};
    padding-left: ${props => props.start && '30px'};
  }
`;

const ClientLogo = styled.div`
  height: auto;
  width: 100%;
  max-height: 100px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: ${props => props.isEven ? '20px' : '0'};
  margin-left: ${props => props.isEven ? '0' : '20px'};
  text-align: center;
  img {
    object-fit: contain;
    max-height: 50px;
    height: 100%;
    max-width: 120px;
  }
  @media(min-width: ${theme.breakpoints.medium}){
    margin: auto;
    max-height: ${props => props.type !== 'light' ? '220px' : '110px'};
    img {
      margin: auto 20px;
      min-height: 35px;
      max-height: ${props => props.type !== 'light' ? '220px' : '110px'};
      max-width: 180px;
      height: 100%;
    }
  }
`;

const ClientsLogos = ({ type, logos }) => {
    return (
        <ClientsList type={type}>
            <RowStyled>
                {logos.map((client, index) => {
                    const { image, position } = client;
                    return (
                        <ColStyled xs={6} sm={6} md={4} lg={3} key={index} logos xsOrder={position} smOrder={position} mdOrder={position}>
                            <Fade duration={750} delay={100}>
                                <ClientLogo isEven={index % 2 === 0} type={type}>
                                    <img src={image.file.url} alt={image.title}/>
                                </ClientLogo>
                            </Fade>
                        </ColStyled>
                    )
                })}
            </RowStyled>
        </ClientsList>
    )
};

export default ClientsLogos;
