import React, {useRef, useState} from "react";
import styled from 'styled-components';
import Service from "./Service";
import theme from "../../styles/theme";
import Slider from "react-slick/lib";
import { Fade } from "react-reveal";

const Container = styled.div`
  max-width: 1610px;
  margin-left: auto;
  margin-right: auto;
`;

const ServicesContainer = styled.div`
  padding: 60px 35px 0;
  
  @media(min-width: ${theme.breakpoints.medium}){
    padding-right: 60px;
    padding-left: 60px;
    padding-top: 100px;
  }
  
  @media(min-width: ${theme.breakpoints.large}){
    padding-top: 140px;
  }
  
  @media(min-width: ${theme.breakpoints.xl}){
    padding-right: 184px;
    padding-left: 186px;
  }
`;

const Services = ({services}) => {
    const [slideIndex, setSlideIndex] = useState(0);
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        arrows: false,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        fade: false,
        swipe: true,
        swipeToSlide: true,
        beforeChange: (current, next) => setSlideIndex(next),
        responsive: [
            {
                breakpoint: 1150,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };


    return (
        <Container>
            <ServicesContainer>
                    <Slider {...settings} ref={sliderRef}>
                        {services.map((service, index) => {
                            return (
                                <Fade duration={350} delay={100} key={index}>
                                    <Service service={service} active={slideIndex === index}/>
                                </Fade>
                            )
                        })}
                    </Slider>
            </ServicesContainer>
        </Container>
    )
};

export default Services;
