import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import fonts from "../styles/fonts";
import theme from "../styles/theme";
import { Fade } from "react-reveal";
import IntersectionObserver from "../components/core/IntersectionObserver";
import Hero from "../components/landing/Hero";
import TextImageBlock from "../components/landing/TextImageBlock";
import Header from "../components/landing/Header";
import Carousel from "../components/landing/Carousel";
import WhatWeDo from "../components/landing/WhatWeDo";
import Contact from "../components/landing/Contact";
import Services from "../components/landing/Services";
import Service from "../components/landing/Service";

const LayoutStyled = styled(Layout)`
  margin: 0;
`;

const BlackBackground = styled.div`
  width: 100%;
  background: ${theme.colors.black};
  ${fonts.MaisonNeueBook};
  box-sizing: border-box;
`;

const ContactContainer = styled.div`  
  @media(min-width: ${theme.breakpoints.large}){
    margin-top: -60px;
  }
`;

const LandingTemplate = (props) => {
  const [theme, setTheme] = useState("dark");
  const changeLogo = (shouldSwitch) => {
    let theme = "dark";

    if (shouldSwitch) {
      theme = "light";
    }
    setTheme(theme);
  };

  const pageBlocks = [];

  const { data, path } = props;
  const { metaTitle, metaDescription, references } = data.page;

  let services = [];

  references.forEach((reference, index) => {
    const type = reference.__typename;
    if (type === "ContentfulHeroBlock") {
      pageBlocks.push(
          <Fade duration={2000} delay={100}>
            <Hero key={index} {...reference} />
          </Fade>
      );
    }
    if (type === "ContentfulBlockTextImage") {
      pageBlocks.push(<TextImageBlock key={index} {...reference} />);
    }
    if (type === "ContentfulHeaderBlock") {
      pageBlocks.push(<Header key={index} {...reference} />);
    }

    if (type === "ContentfulCarousel") {
      pageBlocks.push(<Carousel key={index} {...reference} />);
    }
    if (type === "ContentfulServiceBlock") {
      pageBlocks.push(
        <IntersectionObserver onChange={changeLogo}>
          <BlackBackground className={"cursor-white"}>
            <WhatWeDo key={index} {...reference} />
          </BlackBackground>
        </IntersectionObserver>
      );
    }
    if (type === "ContentfulService") {
      services.push(reference);
    }

    if (services.length !== 0 && type !== "ContentfulService"){
      pageBlocks.push(
          <Services services={services}/>
      );

    }

    if (type === "ContentfulContactBlock") {
      pageBlocks.push(<ContactContainer><Contact key={index} {...reference} /></ContactContainer>);
    }
  });

  let title = `${metaTitle} | Society Studio`

  return (
    <LayoutStyled colorScheme={theme}>
      <SEO
        title={title}
        description={metaDescription}
        fullTitle={true}
        path={path}
      />
      {pageBlocks}
    </LayoutStyled>
  );
};

export default LandingTemplate;

export const pageQuery = graphql`
  query LandingQuery($slug: String!) {
    page: contentfulLandingPage(slug: { eq: $slug }) {
      metaTitle
      metaDescription
      references {
        ... on ContentfulHeroBlock {
          title
          heroImage {
            title
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          heroVideo
          titleVisible
        }
        ... on ContentfulBlockTextImage {
          title
          imagePosition
          content {
            json
          }
          image {
            title
            fluid(maxWidth: 1222, quality: 60) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        ... on ContentfulCarousel {
          title
          slide {
            ... on ContentfulProject {
              slug
              title
              clientName
              thumbnail {
                fluid(maxWidth: 1721, quality: 60) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
        ... on ContentfulContactBlock {
          title
        }
        ... on ContentfulHeaderBlock {
          header
        }
        ... on ContentfulServiceBlock {
          id
          title
          services {
            title
            list {
              json
            }
          }
          clients {
            position
            image {
              title
              file {
                url
              }
            }
          }
        }
        ... on ContentfulService {
          id
          image {
            title
            fluid(maxWidth: 1721, quality: 60) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          list {
            json
          }
          title
          #slug
        }
      }
    }
  }
`;

