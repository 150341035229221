import React, {useRef, useState} from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import Slider from "react-slick/lib";
import theme from "../../styles/theme";
import styled from 'styled-components';
import fonts from "../../styles/fonts";
import ArrowLeft from "../../resources/images/home_page/arrow-left.svg";
import ArrowRight from "../../resources/images/home_page/arrow-right.svg";
import {Fade} from "react-reveal";
import { getProjectLink } from "../../services/links"
import btoa from "btoa"

const Container = styled.div`
  position: relative;
  padding-left: 70px;
  margin-bottom: 50px;
  
  @media(min-width: ${theme.breakpoints.large}){
    padding-left: 285px;
    margin-bottom: 112px;
  }
`;

const Title = styled.h3`
  font-size: 18px;
  line-height: 21.6px;
  ${fonts.MaisonNeueDemi};
  transform: rotate(-90deg);
  position: absolute;
  left: -124px;
  top: calc(50% - 32px);
  
  @media(min-width: ${theme.breakpoints.large}){
    left: 70px;
    top: calc(50% - 54px);
  }
`;

const SlideBlock = styled.div`
  margin: 0 10px;
  position: relative;
  
  @media(min-width: ${theme.breakpoints.large}){
    margin: 0 22px;
  }
`;

const ContentBlock = styled.div`
  padding: 52px 26px 28px 26px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 52px);
  margin-left: auto;
  margin-right: auto;
  background: rgb(0,0,0);
  background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
  
  @media(min-width: ${theme.breakpoints.large}){
    padding: 106px 52px 56px 52px;
    width: calc(100% - 104px);
  }
`;

const StyledLink = styled(Link)`
  color: ${theme.colors.white};
  text-decoration: none;
  margin-bottom: 9px;
  display: block;
  ${fonts.CircularMedium};
  font-size: 24px;
  line-height: 28px;
  
  @media(min-width: ${theme.breakpoints.large}){
    font-size: 32px;
    line-height: 48px;
  }
`;

const Content = styled.div`
  color: ${theme.colors.white};
  ${fonts.MaisonNeueLight};
  font-size: 16px;
  line-height: normal;
  
  @media(min-width: ${theme.breakpoints.medium}){
    font-size: 18px;
  }
`;

const Slide = styled.div`
  height: 300px;
  outline: none;
  
  @media(min-width: ${theme.breakpoints.medium}){
    height: 400px;
  }
      
  @media(min-width: ${theme.breakpoints.large}){
    height: 600px;
  }
  
  @media(min-width: 1489px){
    width: 860px;
  }
`;

const ImgStyled = styled(Img)`
  height: 300px;
  
  @media(min-width: 576px){
    height: 400px;
  }
  
  @media(min-width: ${theme.breakpoints.large}){
    height: 600px;
  }
  
  @media(min-width: 1489px){
    width: 860px;
  }
`;

const ArrowsContainer = styled.div`
  width: max-content;
  margin-right: 0;
  margin-left: auto;
  padding-top: 15px;
  padding-right: 20px;
  
  @media(min-width: ${theme.breakpoints.medium}){
    padding-top: 50px;
    padding-right: 48px;
  }
`;

const ArrowButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  padding: 15px;
  
  img {
    width: 40px;
    height: 12px;
  }
`;

const Carousel = ({ title, slide: slides }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef(null);

  const changeSlide = (value) => {
    if (value === -1) {
      sliderRef.current.slickPrev();
    }
    if (value === 1) {
      sliderRef.current.slickNext();
    }
  }

  const settings = {
    dots: false,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    fade: false,
    swipe: true,
    swipeToSlide: true,
    centerMode: false,
    centerPadding: 100,
    variableWidth: true,
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 1489,
        settings: {
          centerPadding: 150,
          variableWidth: false,
          centerMode: true,
        }
      },
      {
        breakpoint: 1150,
        settings: {
          centerPadding: 150,
          variableWidth: false,
          centerMode: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: 40,
          variableWidth: false,
          centerMode: true,
        }
      },
      {
        breakpoint: 576,
        settings: {
          centerPadding: 20,
          slidesToShow: 1,
          variableWidth: false,
          centerMode: true,
        }
      }
    ]
  };

  return (
      <Container>
        {title && <Title>{title}</Title>}
        <Fade duration={350} delay={100}>
          <Slider ref={sliderRef} {...settings}>
            {slides.map((slide, index) => {
              const {thumbnail, slug, title, clientName} = slide;
              const slideSlugs = slides.map((project) => project.slug)
              const link = `${getProjectLink(slug)}/?list=${btoa(JSON.stringify(slideSlugs))}`
              return (
                  <Slide key={index}>
                  <SlideBlock>
                    <Link to={link}>
                      <ImgStyled fluid={thumbnail.fluid} alt={title}/>
                    </Link>
                    <ContentBlock>
                      <StyledLink to={link}>{clientName}</StyledLink>
                      <Content>{title}</Content>
                    </ContentBlock>
                  </SlideBlock>
                </Slide>
              );
            })}
          </Slider>
          <ArrowsContainer>
            <ArrowButton aria-label={"Previous slide"} onClick={() => changeSlide(-1)}>
              <img src={ArrowLeft} alt={"Arrow left"}/>
            </ArrowButton>
            <ArrowButton aria-label={"Next slide"} onClick={() => changeSlide(1)}>
              <img src={ArrowRight} alt={"Arrow right"}/>
            </ArrowButton>
          </ArrowsContainer>
        </Fade>
      </Container>
  );
};

export default Carousel;
