import React from 'react'
import Form from "../contact/Form";
import { Row, Col } from "styled-bootstrap-grid";
import styled from 'styled-components';
import fonts from "../../styles/fonts";
import theme from "../../styles/theme";

const Container = styled.div`
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  
  @media(min-width: ${theme.breakpoints.medium}){
    max-width: 1600px;
  }
`;

const ContactBlock = styled.div`
  padding: 50px 35px;
  
  @media(min-width: ${theme.breakpoints.medium}){
    padding-left: 60px;
    padding-right: 60px;
  }
  
  @media(min-width: ${theme.breakpoints.large}){
    padding-top: 190px;
    padding-bottom: 90px;
  }
  
  @media(min-width: ${theme.breakpoints.xl}){
    padding-right: 184px;
    padding-left: 186px;
  }
`;

const Title = styled.h2`
  ${fonts.CircularMedium};
  font-size: 50px;
  line-height: 50px;
  max-width: 210px;
  
  @media(min-width: ${theme.breakpoints.medium}){
    padding-top: 20px;
  }
  
  @media(min-width: ${theme.breakpoints.large}){
      font-size: 62px;
      line-height: 76px;
      max-width: 270px;
  }
`;

const FormBlock = styled.div`
  max-width: 730px;
  margin-left: auto;
  margin-right: 0;
`;

const Contact = ({ title }) => {
  return (
    <Container>
        <ContactBlock>
            <Row>
                <Col md={4} xl={3}>
                    <Title>
                        {title}
                    </Title>
                </Col>
                <Col md={8} xl={9} mdAlignSelf={"center"}>
                    <FormBlock>
                        <Form/>
                    </FormBlock>
                </Col>
            </Row>
        </ContactBlock>
    </Container>
  )
};

export default Contact;
