import React from "react";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Circle from "../../resources/images/circle-black-background.svg";
import { Col, Row } from 'styled-bootstrap-grid';
import fonts from "../../styles/fonts";
import theme from "../../styles/theme";
import ClientsLogos from "../core/ClientsLogos";
import breakpoints from "../../styles/breakpoints";

const StyledContainer = styled.div`
  color: white;
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 35px 40px;
  position: relative;
  
  @media(min-width: ${theme.breakpoints.medium}){
    max-width: 1600px;
    padding-top: 218px;
    padding-bottom: 170px;
  }
 
  
  @media(min-width: ${theme.breakpoints.xl}){
    padding: 218px 184px 170px;
  }
`;

const Header = styled.h2`
  ${fonts.CircularMedium};
  font-size: 35px;
  line-height: 42px;
  padding-bottom: 35px;
  
  @media(min-width: ${theme.breakpoints.medium}){
    padding-right: 20px;
  }
  
  @media(min-width: ${theme.breakpoints.large}){
    font-size: 62px;
    line-height: 76px;
    max-width: 482px;
    padding-bottom: 80px;
  }
`;

const CircleImage = styled.img`
  max-height: 320px;
  max-width: 320px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 70px;
  left: -40px;

  @media(min-width: ${theme.breakpoints.medium}){
    max-height: 582px;
    max-width: 582px;
  }
  
  @media(min-width: ${theme.breakpoints.large}){
    max-height: 582px;
    max-width: 582px;
    top: 150px;
    left: unset;
    right: 200px;
  }
`;

const Title = styled.h3`
  ${fonts.MaisonNeueDemi};
  font-size: 18px;
  line-height: 32px;
`;

const Content = styled.div`
  ${fonts.MaisonNeueLight};
  font-size: 18px;
  line-height: 32px;
`;

const Services = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  @media(min-width: ${theme.breakpoints.medium}){
    flex-direction: row;
  }
`;

const Service = styled.div`
  padding-bottom: 20px;
  width: 100%;
  
  @media(min-width: ${breakpoints.small}){
    width: calc(50% - 20px);
    padding-right: 20px;
  }
  
  @media(min-width: ${theme.breakpoints.medium}){
  width: calc(25% - 20px);
  }
`;

const WhatWeDo = ({ title, services, clients }) => {

  return (
    <StyledContainer>
        <Row>
            <Col md={12}>
                <Header>{title}</Header>
            </Col>
            <Col md={12}>
                <Services>
                    {services.map((service, index) => {
                        const { title, list } = service;
                        return (
                            <Service key={index}>
                                <Title>{title}</Title>
                                <span>...</span>
                                <Content>{documentToReactComponents(list.json)}</Content>
                            </Service>
                        );
                    })}
                </Services>
            </Col>
        </Row>
      <CircleImage src={Circle} alt="Background"/>
      <ClientsLogos logos={clients} type={'dark'}/>
    </StyledContainer>
  );
};

export default WhatWeDo;
