import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import theme from "../../styles/theme";
import fonts from "../../styles/fonts";

const HeroBlock = styled.div`
  height: 100vh;
  position: relative;
  max-width: 100vw;

  @media (min-width: ${theme.breakpoints.medium}) {
    max-height: 900px;
  }
`;

const ImgStyled = styled(Img)`
  height: 100%;
  object-fit: cover;
`;

const ContentBlock = styled.div`
  display: block;
  position: absolute;
  padding: 40px;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  max-width: 100vw;
  @media (min-width: ${theme.breakpoints.large}) {
    padding: 70px 70px 80px 70px;
  }
`;

const Title = styled.h1`
  ${fonts.CircularMedium};
  line-height: 50px;
  letter-spacing: -2px;
  color: ${theme.colors.white};
  position: relative;
  z-index: 11;
  padding-bottom: 30px;
  text-decoration: none;
  max-width: 250px;
  font-size: ${props => props.visible? "50px" : "0"};
  &:hover {
    color: ${theme.colors.grey};
  }
  @media (min-width: ${theme.breakpoints.small}) {
    max-width: 600px;
  }
  @media (min-width: ${theme.breakpoints.large}) {
    max-width: 750px;
    line-height: 70px;
    letter-spacing: -5px;
    padding-bottom: 10px;
    font-size: 0;
    font-size: ${props => props.visible? "70px" : "0"};
  }
`;

const HeroVideo = styled.div`
  height: 100vh;
  overflow: hidden;
  padding: 0;
  position: relative;
  max-width: 100vw;
  iframe {
    box-sizing: border-box;
    height: 56.25vw;
    left: 50%;
    min-height: 100vh;
    min-width: 100vw;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    width: 250vh;
  }

  @media (min-width: ${theme.breakpoints.medium}) {
    max-height: 900px;
  }
`;

const Hero = ({ title, heroImage, heroVideo, titleVisible }) => {

  return (
    <HeroBlock>
      {heroVideo && heroVideo !== "/" ? (
        <HeroVideo>
          <iframe
            src={`${heroVideo}?autoplay=1&muted=1&background=1`}
            allow="fullscreen; autoplay"
            allowFullScreen={true}
            title="Project Video"
          />
        </HeroVideo>
      ) : (
        <ImgStyled fluid={heroImage.fluid} alt={title} />
      )}
      <ContentBlock>
        <Title visible={titleVisible ? 1 : 0}>{title}</Title>
      </ContentBlock>
    </HeroBlock>
  );
};

export default Hero;
