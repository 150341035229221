import React from 'react';
import styled from 'styled-components';
import theme from "../../styles/theme";
import fonts from "../../styles/fonts";
import {Fade} from "react-reveal";

const Container = styled.div`
  max-width: 540px;
  padding: 0 35px;
  margin: 22px auto 40px auto;
  
  @media(min-width: ${theme.breakpoints.medium}){
    max-width: 1600px;
  }
  @media(min-width: ${theme.breakpoints.large}){
    margin-top: 172px;
    margin-bottom: 142px;
  }
`;

const HeaderContent = styled.h2`
  text-align: center;
  ${fonts.CircularMedium};
  max-width: 984px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  line-height: 28px;
  
  @media(min-width: ${theme.breakpoints.medium}){
    font-size: 38px;
    line-height: 55px;
  }
`;

const Header = (props) => {
  const {header} = props;

  return (
      <Container>
          <Fade duration={350} delay={100}>
              <HeaderContent>
                  {header}
              </HeaderContent>
          </Fade>
      </Container>
  )
};

export default Header;
