import React from 'react';
import Img from "gatsby-image";
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import Circle from "../../resources/images/profile_page/grey-circle.svg";
import styled from 'styled-components';
import theme from "../../styles/theme";
import fonts from "../../styles/fonts";
import {Fade} from "react-reveal";

const Container = styled.div`
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  
  @media(min-width: ${theme.breakpoints.medium}){
    max-width: 1600px;
  }
`;

const TextImageContainer = styled.div`
  padding: 0 35px;
  margin-bottom: 7px;
  
  @media(min-width: ${theme.breakpoints.medium}){
    margin-bottom: 50px;
  }
  
  @media(min-width: ${theme.breakpoints.large}){
    padding: 0 60px;
  }
  
  @media(min-width: ${theme.breakpoints.xl}){
    padding: 0 184px 0 60px;
  }
`;


const Intro = styled.div`
  padding: 94px 35px;
  position: relative;
  
  @media(min-width: ${theme.breakpoints.large}){
    padding: 215px 35px 135px 192px;
  }
`;

const CircleImage = styled.img`
  position: absolute;
  right: 35px;
  top: 47px;
  height: 330px;
  width: 330px;
  
  @media(min-width: ${theme.breakpoints.medium}){
    height: 420px;
    width: 420px;
    right: 96px;
  }
  
  @media(min-width: ${theme.breakpoints.large}){
    right: 192px;
    top: 120px;
    height: 580px;
    width: 580px;
  }
`;

const Title = styled.h2`
  ${fonts.CircularMedium};
  font-size: 50px;
  line-height: 50px;
  
  @media(min-width: ${theme.breakpoints.medium}){
    font-size: 62px;
    line-height: 74px;
    max-width: 860px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  
  @media(min-width: ${theme.breakpoints.medium}){
    flex-direction: row;
  }
`;

const Col = styled.div`
  width: 100%;
  
  @media(min-width: ${theme.breakpoints.medium}){
    width: 50%;
  }
`;

const ColContent = styled(Col)`  
  order: 2;
  
  @media(min-width: ${theme.breakpoints.medium}){
    order: ${props => props.left ? '1' : '2'};
  }
`;

const ColImage = styled(Col)`
  order: 1;
  
  @media(min-width: ${theme.breakpoints.medium}){
    order: ${props => props.left ? '1' : '2'};
  }`;

const ContentBlock = styled.div`
  @media(min-width: ${theme.breakpoints.medium}){
    max-width: ${props => props.left ? 'unset' : '610px'};
    margin-left: ${props => props.left ? '60px' : '0'};
    margin-right: ${props => props.left ? '0' : '0'};
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const Content = styled.div`
  font-size: 18px;
  line-height: 28px;
  ${fonts.MaisonNeueLight};
  padding-top: 35px;
  
  p {
    margin-bottom: 28px;
  }
  a {
    color: ${theme.colors.black};
  }
  
  @media(min-width: ${theme.breakpoints.medium}){
    max-width: 488px;
    padding-top: 0;
    margin-left: auto;
    margin-right: 0;
  }
`;

const ImgStyledLeft = styled(Img)`
  height: 100%;
  
  @media(min-width: ${theme.breakpoints.medium}){
    max-width: 610px;
    margin-left: 0;
    margin-right: auto;
  }
`;

const ImgStyledRight = styled(Img)`
    height: 100%;
    
    @media(min-width: ${theme.breakpoints.medium}){
      margin-left: 60px;
      max-width: 488px;
    }
    
    @media(min-width: ${theme.breakpoints.large}){
      margin-right: 0;
      margin-left: auto;
    }
`;

const TextImageBlock = ({ content, image, imagePosition, title }) => {
  let hasBackground = false;
  if (title) {
    hasBackground = true;
  }

  return (
      <Container>
          {hasBackground &&
          <Fade bottom duration={1000}>
              <Intro>
                  {hasBackground &&
                  <CircleImage src={Circle} alt="background"/>
                  }
                  {title && <Title>{title} </Title>} <br/>
              </Intro>
          </Fade>
          }
          <Fade duration={350} delay={100}>
              <TextImageContainer>
                  <Row>
                      <ColContent>
                          <ContentBlock left={imagePosition ? 1 : 0}>
                              {content && <Content>{documentToReactComponents(content.json)}</Content>}
                          </ContentBlock>
                      </ColContent>
                      <ColImage left={imagePosition ? 1 : 0}>
                          {imagePosition ?
                              <ImgStyledLeft fluid={image.fluid} alt={image.title}/>
                              :
                              <ImgStyledRight fluid={image.fluid} alt={image.title}/>
                          }
                      </ColImage>
                  </Row>
              </TextImageContainer>
          </Fade>
    </Container>
  )
};

export default TextImageBlock;
