import React, {useState} from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import styled from 'styled-components';
import theme from "../../styles/theme";
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";

const ServiceContainer = styled.div`
  padding-left: 5px;
  padding-right: 5px;
`;

const ImageBlock = styled.div`
  position: relative;
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.black};
`;

const Title = styled.div`
  ${fonts.MaisonNeueDemi};
  font-size: 18px;
  padding-top: 30px;
  padding-bottom: 30px;
  
  @media(min-width: ${breakpoints.large}){
    padding-bottom: 4px;
  }
`;

const Overlay = styled.div`
  background: ${theme.colors.black};
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
  opacity: ${props => props.active ? '0.4' : '0'};
  transition: 0.2s;
  
  @media(min-width:${theme.breakpoints.large}){
    opacity: ${props => props.isHovered ? '0.4' : '0'};
  }
`;

const ImgStyled = styled(Img)`
  height: 400px;
`;

const ServicesList = styled.div`
  position: absolute;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  color: ${theme.colors.white};
  ${fonts.MaisonNeueDemi};
  font-size: 16px;
  line-height: 22px;
  opacity: ${props => props.active ? '1' : '0'};
  transition: 0.2s;
  z-index: 19;
  
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  
  ul span:after{
    content: " / ";
  }
  
  ul span:last-of-type:after {
    content: "";
  }
  
  @media(min-width:${theme.breakpoints.large}){
    opacity: ${props => props.isHovered ? '1' : '0'};
  }
`;

const options = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: (node) => {
      const content = node.content[0].content[0].value;
      return <span>{content}</span>;
    },
  },
};

const Service = ({ service, active }) => {
    const [isHovered, setIsHovered] = useState(false);


    return (
        <ServiceContainer>
            <LinkStyled to={service.slug} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <ImageBlock onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    <Overlay isHovered={isHovered} active={active}/>
                    <ImgStyled fluid={service.image.fluid} alt={service.title}/>
                    <ServicesList isHovered={isHovered} active={active}>{documentToReactComponents(service.list.json, options)}</ServicesList>
                </ImageBlock>
                <Title>{service.title}</Title>
            </LinkStyled>
        </ServiceContainer>
  );


};

export default Service;
